import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div id="single-spa-application:news-home"></div>
      <div id="single-spa-application:news"></div>
    </div>
  );
}

export default App;
