import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import { registerApplication, start } from "single-spa";
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

registerApplication({
  name: "ui-library",
  app: () => window.System.import("@lexis/ui-library"),
  activeWhen: ["/"],
  //customProps: { product: "LHL-Admin", layouts: "@lexis/legislacion" }
});

window.System.import("@lexis/news-api").then(() => {  
})


registerApplication({
  name: "news-home",
  app: () => window.System.import("@lexis/news-home"),
  activeWhen: ["/"],
  //customProps: { product: "LHL-Admin", layouts: "@lexis/legislacion" }
});

registerApplication({
  name: "news",
  app: () => window.System.import("@lexis/news"),
  activeWhen: ["/"],
  //customProps: { product: "LHL-Admin", layouts: "@lexis/legislacion" }
});


 window.System.import("@lexis/news-style").then(() => {
  // Activate the layout engine once the styleguide CSS is loaded
  start();
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
